@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;900&display=swap');
body{
    width: 100%;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    line-height: 22px;
    font-size: 14px;
}
ul{
    padding: 0;
    margin: 0;
}
li{
    list-style: none;
}
a{
    text-decoration: none;
}


.navbar{
    background: rgb(56,71,221);
    background: linear-gradient(90deg, rgba(56,71,221,1) 39%, rgba(45,121,241,1) 100%);
    padding-bottom: 80px;
    padding-top: 10px;
}
.logo{
    img{
        width: 300px;
    }
}
.navbar-light .navbar-nav .nav-link {
    color: #fff;
    font-weight: 500;
    padding-left: 2px;
    padding-right: 2px;
    margin-left: 24px;
    margin-right: 24px;
    border-bottom: 2px solid transparent;
    font-size: 16px;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: #E7C26F;
    border-bottom: 2px solid #E7C26F;
}
.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
    color: #E7C26F;
    border-bottom: 2px solid #E7C26F;
}
.login-body{
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 100px;
}
.login-box{
    position: relative;
    top: -50px; 
}
.login-box-body{
    padding: 30px 40px;
    background: #fff;
    color: #536685;
    -webkit-border-bottom-right-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-bottomright: 10px;
    -moz-border-radius-bottomleft: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0 0 8px rgba(0,0,0,0.25);
    overflow: hidden;
    h4{
        font-size: 28px;
        margin-top: 15px;
    }
    p{
        font-size: 14px;
        margin-bottom: 20px;
    }
}
.tab-menu{
    ul{
        display: flex;
        justify-content: space-between;
        li{
            width: 49.5%;
            a{
                display: block;
                padding: 14px 15px;
                text-align: center;
                color: #536685;
                font-size: 18px;
                background: #C2C7E2;
                -webkit-border-top-left-radius: 10px;
                -webkit-border-top-right-radius: 10px;
                -moz-border-radius-topleft: 10px;
                -moz-border-radius-topright: 10px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            &.active{
                a{
                    background: #fff;
                    color: #287AEB;
                }
            }
        }
    }
}
.seprator{
    border-bottom: 1px solid #e9ecf4;
}
.login-with{
    ul{
        display: flex;
        justify-content: center;
        li{
            margin: 8px 5px;
            a{
                color: #536685;
                font-size: 15px;
                border: 1px solid #536685;
                border-radius: 10px;
                padding: 8px 12px;
                display: block;
                span{
                    margin-left: 15px;
                }
            }
        }
    }
}
.or{
    position: relative;
    width: 64px;
    margin: 15px auto;
    text-align: center;
    &::before{
        content: "";
        width: 30px;
        height: 1px;
        background: #536685;
        position: absolute;
        left: -15px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    &::after{
        content: "";
        width: 30px;
        height: 1px;
        background: #536685;
        position: absolute;
        right: -15px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
}
.form-control {
    background: #E9ECF4;
    color: #000;
    border-radius: 10px;
    min-height: 52px;
    border: 1px solid #E9ECF4;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
}
.form-select{
    // background: #E9ECF4;
    color: #000;
    border-radius: 10px;
    min-height: 52px;
    border: 1px solid #E9ECF4;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px; 
    color: #536685;
}
.btn-primary {
    color: #fff;
    background: #2d79f1;
    background: linear-gradient(90deg, rgba(45,121,241,1) 0%, rgba(56,71,221,1) 100%);
    border-color: #0d6efd;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    border-radius: 10px;
    box-shadow: 0 0 8px rgba(0,0,0,0.25);
    padding: 12px 15px;
}
.btn-success{
    color: #fff;
    background: #64eac4;
    background: linear-gradient(180deg, rgba(100,234,196,1) 0%, rgba(33,213,161,1) 100%);
    border-color: #64eac4;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    border-radius: 30px;
    box-shadow: 0 0 8px rgba(0,0,0,0.25);
}
.btn-success:hover {
    background: linear-gradient(180deg, rgba(100,234,196,1) 0%, rgba(33,213,161,1) 100%);
    border-color: #64eac4;
}
.btn-check:focus+.btn-success, .btn-success:focus {
    background: linear-gradient(180deg, rgba(100,234,196,1) 0%, rgba(33,213,161,1) 100%);
    border-color: #64eac4;
}
.btn-light {
    color: #536685;
    background-color: #E9ECF4;
    border-color: #E9ECF4;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 500;
}
.btn-light:hover {
    background-color: #E9ECF4;
    border-color: #E9ECF4;
}
.btn-danger {
    color: #fff;
    background: #F02C2C;
    border-color: #F02C2C;
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px;
    box-shadow: 0 0 8px rgba(0,0,0,0.25);
    padding: 12px 15px;
}
.btn-secondary {
    background: #E9ECF4;
    border-color: #E9ECF4;
    font-size: 16px;
    color: #536685;
    font-weight: 500;
    border-radius: 10px;
    padding: 12px 15px;
}
.form-group{
    position: relative;
}
.form-icon{
    position: absolute;
    top: 12px;
    right: 22px;
    img{
        width: 18px;
    }
}
.was-validated .form-control:invalid{
    background-image: none;
}
.was-validated .form-control:valid {
    background-image: none;
}
.not-member{
    color: #287AEB;
    text-align: center;
    a{
        text-decoration: underline;
        color: #287AEB;
        font-weight: 500;
    }
}
.forgot{
    color: #287AEB;  
    font-weight: 500;
}
.remember{
    label{
        color: #287AEB;  
        font-weight: 500;
    }
}
.form-check-input.is-valid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label {
    color: #287AEB;  
}
.modal-backdrop.show {
    opacity: .6;
}
.modal-text{
    font-size: 16px;
    color: #536685;
}
.modal-header{
    background: rgb(56,71,221);
    background: linear-gradient(90deg, rgba(56,71,221,1) 39%, rgba(45,121,241,1) 100%);
}
.modal-title{
    color: #fff;
    font-size: 20px;
}
.modal-content {
    border-radius: 10px;
    border: none;
}
.modal-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.signup-box{
    box-shadow: 0 0 8px rgba(0,0,0,0.25);
    margin: 30px 0;
    display: flex;
    background: rgb(56,71,221);
    background: linear-gradient(90deg, rgba(56,71,221,1) 39%, rgba(45,121,241,1) 100%);
    .signup-left{
        width: 40%;
        padding: 30px 26px;
        color: #fff;
    }
    .signup-right{
        width: 60%;
        background: #fff;
        padding: 15px;
        .form-control{
            background: #fff;
            box-shadow: 0 0 8px rgba(0,0,0,0.25);
        }::-webkit-scrollbar {
            width: 6px;
        }
        ::-webkit-scrollbar-track {
            background: #f1f1f1; 
        }
        ::-webkit-scrollbar-thumb {
            background: #287AEB; 
        }
        ::-webkit-scrollbar-thumb:hover {
            background: #185dbd; 
        }
    }
}
:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}
.signup-step{
    margin-top: 320px;
    ul{
        display: flex;
        justify-content: center;
        li{
            height: 5px;
            background: rgba(255,255,255,0.5);
            border-radius: 4px;
            width: 46px;
            margin: 4px 4px;
            &.active{
                background: rgba(255,255,255,1);
            }
        }
    }
}
.search-btn{
    background: #0d6efd;
    color: #fff;
    border-radius: 5px;
    padding: 8px 10px;
    position: absolute;
    top: 5px;
    right: 6px;
}
.btn-pill{
    border-radius: 30px;
}
.signup-back-btn{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}
.btn-link{
    color: #536685;
    text-decoration: none;
}
.text-primary{
    color: #287AEB !important;
}
.text-muted{
    color: #536685 !important;
}
.fw-500{
    font-weight: 500;
}
.grade-batch{
    height: 200px;
    overflow-y: scroll;
    ul{
        display: flex;
        flex-wrap: wrap;
        li{
            width: 23%;
            border-radius: 10px;
            background-color: #fff;
            box-shadow: 0 0 8px rgba(0,0,0,0.25);
            margin: 10px 4px;
            padding: 12px;
            background-repeat: no-repeat;
            height: 128px;
            overflow: hidden;
            transition: all ease-in-out 0.2s;
            cursor: pointer;
            img{
                max-width: 100%;
            }
            &.selected{

                background: rgb(56,71,221);
                background: linear-gradient(90deg, rgba(56,71,221,1) 39%, rgba(45,121,241,1) 100%);
                // border: 1px solid rgb(56,71,221);
                img{
                    color: #fff;
                }

                // border: 2px solid rgb(56,71,221);
                // background-color: rgb(56,71,221);
                // background-color: linear-gradient(90deg, rgba(56,71,221,1) 39%, rgba(45,121,241,1) 100%);
                // background-position: 128px 0;
                // img{
                //     position: relative;
                //     top: -120px;
                // }
            }
            &:hover{

                background: rgb(56,71,221);
                background: linear-gradient(90deg, rgba(56,71,221,1) 39%, rgba(45,121,241,1) 100%);
                // border: 1px solid rgb(56,71,221);
                img{
                    color: #fff;
                }



                // border: 2px solid rgb(56,71,221);
                // background-color: rgb(56,71,221);
                // background-color: linear-gradient(90deg, rgba(56,71,221,1) 39%, rgba(45,121,241,1) 100%);
                // background-position: 128px 0;
                // img{
                //     position: relative;
                //     top: -120px;
                // } 
            }
        }
    }
}

.step-progress{
    margin-top: 30px;
}
.step-progress-bar{
    height: 12px;
    background: rgba(255,255,255,0.5);
    border-radius: 10px;
    width: 100%;
    margin: 8px 0;
    overflow: hidden;
}
.step-progress-text{
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-weight: 500;
    margin: 0;
}
.step-progress-percent{
    height: 12px;
    background: #fff;
    border-radius: 10px;
}
.search{
    width: 100%;
}

.choose-avatar{
    ul{
        display: flex;
        flex-wrap: wrap;
        li{
            width: 23%;
            border-radius: 10px;
            // box-shadow: 0 0 8px rgba(0,0,0,0.20);
            margin: 10px 4px;
            margin-top: 0;
            margin-bottom: 15px;
            padding: 20px;
            padding-top: 40px;
            padding-bottom: 0;
            transition: all ease-in-out 0.2s;
            cursor: pointer;
            img{
                max-width: 100%;
            }
            &.selected{
                background: rgb(56,71,221);
                background: linear-gradient(90deg, rgba(56,71,221,1) 39%, rgba(45,121,241,1) 100%);
                // border: 1px solid rgb(56,71,221);
                img{
                    color: #fff;
                }
            }
            &:hover{
                background: rgb(56,71,221);
                background: linear-gradient(90deg, rgba(56,71,221,1) 39%, rgba(45,121,241,1) 100%);
                // border: 1px solid rgb(56,71,221);
                img{
                    color: #fff;
                }
            }
        }
    }
}
.child-avt-select{
    ul{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        li{
            width: 30%; 
            cursor: pointer;
            color: #536685;
            text-align: center;
            margin-bottom: 15px;
            h6{
                font-size: 14px;
                margin: 0;
            }
            p{
                font-size: 12px;
                margin: 0;
            }
            .child-avatar{
                border-radius: 10px;
                background: #E3E7F0;
                box-shadow: 0 0 8px rgba(0,0,0,0.20);
                margin: 10px 8px;
                margin-top: 0;
                margin-bottom: 10px;
                padding: 20px;
                padding-top: 40px;
                padding-bottom: 0;
                transition: all ease-in-out 0.2s;
                height: 158px;
                overflow: hidden;
                img{
                    max-width: 100%;
                }
            }
            &.selected{
                .child-avatar{
                    background: rgb(56,71,221);
                    background: linear-gradient(90deg, rgba(56,71,221,1) 39%, rgba(45,121,241,1) 100%);
                }
                img{
                    color: #fff;
                }
            }
            &:hover{
                .child-avatar{
                    background: rgb(56,71,221);
                    background: linear-gradient(90deg, rgba(56,71,221,1) 39%, rgba(45,121,241,1) 100%);
                }
                img{
                    color: #fff;
                }
            }
        }
    }
}
.add-details{
    height: 372px;
    padding: 0 15px;
    overflow-y: scroll;
    .form-control {
        color: #000;
        border-radius: 10px;
        min-height: 52px;
        border: 1px solid #E9ECF4;
        font-size: 14px;
        padding-left: 15px;
        padding-right: 15px;
        box-shadow: none !important;
    }
}
.verify-account{
    color: #536685;
    text-align: center;
    padding-top: 30px;
    p{
        a{
            color: #F53C3C;
            text-decoration: underline;
        }
    }
}
.otp-field{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .form-control{
        width: 14%;
        box-shadow: none !important;
        background: #F3F5FC !important;
        border-color: #F3F5FC !important;
        font-size: 20px;
        color:#536685;
        text-align: center;
    }
}
.otp-input{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.otp-input > div{
    width: 14%;
}
.otp-input > div .form-control{
    width: 100% !important;
    box-shadow: none !important;
    background: #F3F5FC !important;
    border-color: #F3F5FC !important;
    font-size: 20px;
    color:#536685;
    text-align: center;
}

.subject-name{
    height: 320px;
    overflow-y: scroll;
    margin-top: 15px;
    ul{
        display: flex;
        flex-wrap: wrap;
        li{
            width: 23%;
            border-radius: 10px;
            background: #fff;
            box-shadow: 0 0 8px rgba(0,0,0,0.25);
            margin: 10px 4px;
            padding: 12px;
            transition: all ease-in-out 0.2s;
            cursor: pointer;
            .sub-img{
                height: 104px;
                overflow: hidden;
                transition: all ease-in-out 0.2s;
            }
            h6{
                text-align: center;
                margin-bottom: 0;
                margin-top: 5px;
                font-size: 14px;
            }
            .blue{
                color: #3A76E4;
                
            }
            .green{
                color: #029169;
            }
            .yellow{
                color: #F0B919;
                
            }
            img{
                max-width: 100%;
            }
            &.selected{
                background: rgb(56,71,221);
                background: linear-gradient(90deg, rgba(56,71,221,1) 39%, rgba(45,121,241,1) 100%);
                .sub-img{
                    img{
                        position: relative;
                        top: -110px;
                    }
                }
                .blue{
                    color: #fff;
                    
                }
                .green{
                    color: #fff;
                }
                .yellow{
                    color: #fff;
                }
            }
            &:hover{
                background: rgb(56,71,221);
                background: linear-gradient(90deg, rgba(56,71,221,1) 39%, rgba(45,121,241,1) 100%);
                .sub-img{
                    img{
                        position: relative;
                        top: -110px;
                    }
                }
                .blue{
                    color: #fff;
                    
                }
                .green{
                    color: #fff;
                }
                .yellow{
                    color: #fff;
                }
            }
        }
    }
}
.subject-name{
    .label-wrap{
        display: flex;
        flex-wrap: wrap;
        label{
            width: 23%;
            border-radius: 10px;
            background: #fff;
            box-shadow: 0 0 8px rgba(0,0,0,0.25);
            margin: 10px 4px;
            padding: 12px;
            transition: all ease-in-out 0.2s;
            cursor: pointer;
            .sub-img{
                height: 104px;
                overflow: hidden;
                color: #fff;
                transition: all ease-in-out 0.2s;
            }
            h6{
                text-align: center;
                margin-bottom: 0;
                margin-top: 5px;
                font-size: 14px;
            }
            .blue{
                color: #3A76E4;
                
            }
            .green{
                color: #029169;
            }
            .yellow{
                color: #F0B919;
                
            }
            img{
                max-width: 100%;
                color: #fff;
            }
        }
        input[type=radio]{
            display: none;
        }
        input[type=checkbox]{
            display: none;
        }
        input[type=radio]:checked + label{
            background: rgb(56,71,221);
            background: linear-gradient(90deg, rgba(56,71,221,1) 39%, rgba(45,121,241,1) 100%); 
            .sub-img{
                img{
                    position: relative;
                    top: -110px;
                    color: #fff;
                }
            }
            .blue{
                color: #fff;
                
            }
            .green{
                color: #fff;
            }
            .yellow{
                color: #fff;
            }
        }
        input[type=checkbox]:checked + label{
            background: rgb(56,71,221);
            background: linear-gradient(90deg, rgba(56,71,221,1) 39%, rgba(45,121,241,1) 100%); 
            .sub-img{
                img{
                    // position: relative;
                    // top: -110px;
                    color: #fff;
                }
            }
            .blue{
                color: #fff;
                
            }
            .green{
                color: #fff;
            }
            .yellow{
                color: #fff;
            }
        }
    }
}
.user-avatar{
    .label-wrap{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        label{
            width: 30%;
            transition: all ease-in-out 0.2s;
            cursor: pointer;
            text-align: center;
            margin-bottom: 15px;
            .child-avatar{
                border-radius: 10px;
                background: #E3E7F0;
                box-shadow: 0 0 8px rgb(0 0 0 / 20%);
                margin: 10px 8px;
                margin-top: 0;
                margin-bottom: 10px;
                padding: 20px;
                padding-top: 40px;
                padding-bottom: 0;
                transition: all ease-in-out 0.2s;
                height: 158px;
                overflow: hidden;
                img{
                    max-width: 100%;
                }
            }
            h6{
                font-size: 14px;
                margin: 0;
            }
            p{
                font-size: 12px;
                margin: 0;
            }
        }
        input[type=radio]{
            display: none;
        }
        input[type=checkbox]{
            display: none;
        }
        input[type=radio]:checked + label .child-avatar{
            background: rgb(56,71,221);
            background: linear-gradient(90deg, rgba(56,71,221,1) 39%, rgba(45,121,241,1) 100%); 
        }
        input[type=checkbox]:checked + label .child-avatar{
            background: rgb(56,71,221);
            background: linear-gradient(90deg, rgba(56,71,221,1) 39%, rgba(45,121,241,1) 100%); 
        }
    }
}
.user-are{
    display: flex;
    margin-bottom: 15px;
    .user-avatar{
        width: 64px;
        height: 64px;
        background: #FFFFFF;
        border-radius: 8px;
        margin-right: 15px;
        padding: 5px;
        padding-bottom: 0;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .user-text{
        width: 70%;
        h4{
            font-size: 20px;
            margin-top: 8px;
            margin-bottom: 5px;
        }
        h6{
            font-size: 16px;
            margin: 0;
        }
    }
}
.btn-outline-secondary{
    border-radius: 10px;
    font-size: 14px;
    padding: 8px 20px;
}
.available-date{
    .form-control{
        box-shadow: none !important;
    }
}
.DatePicker_dateDayItem__SAif5 {
    height: auto !important;
    border-radius: 30px !important;
    color: #536685;
    // border: 2px solid #287AEB;
}
.DatePicker_button__Xz83C{
    background: #287AEB !important;
}
.DatePicker_dateLabel__JkJRM {
    font-weight: 500;
}
.DatePicker_monthYearLabel__1OBtO{
    color: #536685 !important;
    font-weight: 500;
    text-align: center;
}

.dashboard-wrap{
    height: 100%;
    display: flex; 
    background: #E9ECF4;
    &>.left{
        width: 19%;
        background: #fff;
        padding: 15px 10px;
        padding-left: 0;
        .dash-logo{
            margin-bottom: 50px;
            padding-left: 15px;
        }
        .dash-menu{
            ul{
                li{
                    margin: 8px 0;
                    a{
                        color: #536685;
                        font-size: 18px;
                        padding: 12px 20px;
                        -webkit-border-top-right-radius: 8px;
                        -webkit-border-bottom-right-radius: 8px;
                        -moz-border-radius-topright: 8px;
                        -moz-border-radius-bottomright: 8px;
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;
                        display: block;
                        padding-left: 40px;
                        display: flex;
                        align-items: center;
                        span{
                            background-repeat: no-repeat;
                            width: 30px;
                            height: 30px;
                            overflow: hidden;
                            background-position: 0px 0;
                            display: inline-block;
                            background-size: cover;
                            margin-right: 10px;
                        }
                        &.active{
                            background: rgb(45,121,241);
                            background: linear-gradient(180deg, rgba(45,121,241,1) 0%, rgba(56,71,221,1) 100%);
                            color: #fff;
                            span{
                                background-position: 0px -30px;
                            }
                        }
                    }
                }
            }
        }
    }
    &>.middle{
        width: 56%;
        padding: 15px 20px;
    }
    &>.right{
        width: 25%;
        padding: 15px 15px;
    }
}
.search-input{
    background: #fff;
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 8px;
    min-height: 52px;
    padding-right: 62px;
}
.dash-box{
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
    img{
        max-width: 100%;
    }
    .dash-box-text{
        position: absolute;
        top: 20px;
        left: 20px;
        h4{ 
            font-size: 18px;
            color: #fff;
            margin-bottom: 0;
        }
        p{
            font-size: 12px;
            color: #fff;
            font-weight: 700;
        }
    }
}
.dash-box-text-blank{
    text-align: center;
    border: 2px dashed #8E9BB1;
    padding: 20px 20px;
    padding-top: 35px;
    max-height: 145px;
    max-width: 145px;
    cursor: pointer;
    h4{
        font-size: 20px;
        margin-top: 15px;
    }
}
.assignment-box{
    box-shadow: 0 0 10px rgba(0,0,0,0.15);
    padding: 15px;
    border-radius: 15px;
    min-height: 210px;
    background: #fff;
    margin-bottom: 10px;
    h5{
        font-size: 18px;
        margin-top: 40px;
    }
    p{
        font-size: 12px;
        margin-bottom: 0;
        line-height: 14px;
    }
}
.text-purple{
    color: #9C5CEE;
}
.text-yellow{
    color: #FEB722;
}
.text-red{
    color: #D22632;
}
.text-green{
    color: #75953D;
}
.text-info{
    color: #287AEB !important;
}
.folder-img{
    min-height: 82px;
}
.tutor-item{
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 15px 10px;
    border-radius: 10px;
    &.active{
        background: rgb(114,171,254);
        background: linear-gradient(180deg, rgba(114,171,254,1) 0%, rgba(59,60,221,1) 100%);
        .tutor-subject{
            h6{
                color: #fff;
            }
            h5{
                color: #fff;
            }
            p{
                color: #fff;
            }
        }
        .tutor-icon{
            img{
                opacity: 1;
            }
        }
    }
}
.tutor-wrap{
    display: flex; 
    width: 76%; 
}
.tutor-item{
    margin-bottom: 10px;
}
.tutor-subject{
    h6{
        margin: 0;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 3px;
        color: #536685;
    }
    h5{
        font-size: 13px;
        font-weight: 500;
        color: #536685;
    }
    p{
        margin: 0;
        color: #536685;
        margin-bottom: 3px;
        font-size: 13px;
    }
    ul{
        display: flex;
        li{
            text-align: center;
            padding: 8px 15px;
            border-radius: 5px;
            color: #fff;
            margin-right: 15px;
            &:nth-child(1){
                background: rgb(157,93,239);
                background: linear-gradient(180deg, rgba(157,93,239,1) 0%, rgba(181,126,251,1) 100%);
            }
            &:nth-child(2){
                background: rgb(254,179,26);
                background: linear-gradient(180deg, rgba(254,179,26,1) 0%, rgba(254,201,70,1) 100%);
            }
            &:nth-child(3){
                background: rgb(211,40,51);
                background: linear-gradient(180deg, rgba(211,40,51,1) 0%, rgba(217,68,79,1) 100%);
            }
            &:nth-child(4){
                background: rgb(117,149,61);
                background: linear-gradient(180deg, rgba(117,149,61,1) 0%, rgba(130,173,60,1) 100%);
            }
            &:nth-child(5){
                background: rgb(50,71,215);
                background: linear-gradient(180deg, rgba(50,71,215,1) 0%, rgba(40,118,233,1) 100%);
            }
        }
    }
}
.tutor-avatar{
    width: 90px;
    height: 90px;
    border-radius: 6px;
    overflow: hidden;
    img{
        width: 100%;
    }
}
.tutor-subject{
    padding-left: 15px;
    width: 85%;
}
.tutor-action{
    ul{
        display: flex;
        margin-top: 10px;
        li{
            margin-left: 10px;
            a{
                background: #C5DBF9;
                border-radius: 10px;
                color: #fff;
                text-align: center;
                display: block;
                padding: 10px;
                width: 44px;
                font-size: 20px;
            }
            &.active{
                a{
                    background: #287AEB;
                }
            }
        }
    }
}
.tutor-icon{
    width: 120px;
    img{
        max-width:100%;
        opacity: 0.15;
    }
}
.user-short-text{
    display: flex;
    position: relative;
    z-index: 2;
}
.user-short-profile{
    display: flex;
    background: #fff;
    color: #536685;
    border-radius: 12px;
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 8px;
    padding: 10px 15px;
    width: 100%;
    justify-content: space-between;
    &:hover{
        background: #2d79f1;
        background: linear-gradient(180deg, #2d79f1 0%, #3847dd 100%);
        color: #fff;


        .dropdown {


            button {
                color: white;
            }

        }

    }
    .user-avatar{
        width: 38px;
        height: 40px;
        border-radius: 8px;
        margin-right: 10px;
        background: #E9ECF4;
        padding: 4px;
        padding-bottom: 0;
        img{
            width: 100%;
        }
    }
    .user-short-name{
        width: 68%;
    }
    .drop-down{
        margin-top: 10px;
        margin-left: 20px;
    }
}
.user-short-name{
    h5{
        font-size: 16px;
        // color: #536685;
        margin: 0;
    }
    p{
        font-size: 13px;
        // color: #536685;
        margin: 0;
    }
}
.notification-bell{
    position: relative;
    margin-right: 10px;
    margin-top: 15px;
    .noti-dot{
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #DD2423;
    }
}
.user-added{
    .user-short-text-two{
        width: 86%;
        margin-left: 42px;
    }
    &:hover{
        .user-short-text-two{
            display: flex;
        }
    }
}
.user-short-text-two{
    display: flex;
    background: #fff;
    color: #536685;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 8px;
    padding: 10px 15px;
    justify-content: space-between;
    padding-top: 20px;
    position: relative;
    top: -10px;
    z-index: 1;
    display: none;
    &:hover{
        background: #2d79f1;
        background: linear-gradient(180deg, #2d79f1 0%, #3847dd 100%);
        color: #fff;
    }
    .user-avatar{
        width: 38px;
        height: 40px;
        border-radius: 8px;
        margin-right: 10px;
        background: #E9ECF4;
        padding: 4px;
        padding-bottom: 0;
        img{
            width: 100%;
        }
    }
    .user-short-name{
        width: 68%;
    }
    .drop-down{
        margin-top: 10px;
        margin-left: 20px;
    }
}
.sub-heading{
    font-size: 1.25rem;
    span{
        font-size: 14px;
        font-weight: normal;
    }
}
.upcom-class{
    background: #fff;
    padding: 15px;
    border-radius: 12px;
    margin-top: 20px;
}
.upcoming-class-schedule{
    margin-top: 40px;
    ul{
        position: relative;
        &:after{
            // content: "";
            // position: absolute;
            // top: 0;
            // left: 50px;
            // width: 2px;
            // border-left: 2px dashed #B7B7B7;
            // height: 100%;
            // z-index: 1;
        }
        li{
            display: flex;
            background: #fff;
            position: relative;
            z-index: 2;
            min-height: 60px;
            .left{
                width: 18%;
                color: #536685;
                font-size: 14px;
            }
            .right{
                display: flex;
                justify-content: space-between;
                width: 82%;
                padding: 10px 15px;
                border-left: 4px solid #fff;
                h5{
                    color: #536685;
                    font-size: 14px;
                }
                p{
                    color: #536685;
                    font-size: 12px;
                    margin: 0;
                }
                .dropdown-menu{
                    transform: translate(-25px, -38px) !important;
                    font-size: 14px;
                    padding: 0;
                    min-width: 6rem;
                }
            }
            .three-dot{
                width: 20px;
                
            }
            &.no-class{
                .right{
                    border-left: 2px dashed #B7B7B7;
                }
            }
            &.eight-am{
                .right{
                    background: #BFD5FA;
                    border-left: 4px solid #2C75EE;
                }
            }
            &.nine-am{
                .right{
                    background: #DEC9F8;
                    border-left: 4px solid #914CE9;
                }
            }
            &.ten-am{
                .right{
                    background: #FFE8BA;
                    border-left: 4px solid #FEB41C;
                }
            }
            &.twelve-pm{
                .right{
                    background: #C5FFC9;
                    border-left: 4px solid #40FF4E;
                } 
            }
            &.one-pm{
                .right{
                    background: #CEDFFF;
                    border-left: 4px solid #5C96FF;
                } 
            }
        }
        
    }
}
.mobile-toggle{
    display: none;
}

.notfound{
    text-align: center;
    margin-top: 60px;
}
.notfound .notfound-404 h1 {
    font-size: 186px;
    margin: 0px;
    background: linear-gradient(90deg, rgba(56,71,221,1) 39%, rgba(45,121,241,1) 100%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    text-transform: uppercase;
    font-weight: 300;
}
.notfound a {
    color: rgba(56,71,221,1);
    font-weight: 500;
    text-decoration: none;
    border-bottom: 1px dashed rgba(56,71,221,1);
    border-radius: 2px;
}

.MuiIconButton-root {
    padding: 7px !important;
    font-size: 14px !important;
}
.MuiGrid-root h3{
    font-size: 18px !important;
    color: #0d6efd;
}
.MuiGrid-root.MuiGrid-item .MuiButton-containedPrimary{
    display: none;
}
.modal-form{
    .form-control{
        color: #536685;
    }
    .form-select{
        background: #E9ECF4;
        color: #000;
        border-radius: 10px;
        border: 1px solid #E9ECF4;
        font-size: 14px;
        padding-left: 15px;
        padding-right: 15px;
        min-height: 52px;
        color: #536685;
    }
}
.btn-sm{
    padding: 4px 10px;
    font-size: 14px;
}
.attachment{
    position: absolute;
    bottom: 6px;
    right: 6px;
    display: flex;
}
.attach-file{
    width: 100px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    input[type="file"]{
        position: absolute;
        top: 0;
        left: 0;
        width: 90px;
        opacity: 0;
    }
    p{
        margin: 0;
        font-size: 13px;
        margin-left: 7px;
    }
}
.testimoial-box{
    text-align: center;
    position: relative;
    padding: 0 40px;
    &:after{
        content: "\f10d";
        font-family: FontAwesome;
        color: #ECEFF2;
        font-size: 40px;
        position: absolute;
        top: 68px;
        left: 0;
    }
    .testi-avatar{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        margin: 10px auto;
        margin-top: 15px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    p{
        color: #707070;
        font-size: 14px;
    }
}
.class-date-time{
    margin-bottom: 5px;
    margin-top: 5px;
    ul{
        display: flex;
        justify-content: space-between;
        li{
            font-size: 16px;
            font-weight: 500;
            color: #536685;
        }
    }
}

.card{
    background: #fff;
    padding: 15px;
    border-radius: 12px;
    margin-top: 20px;
    border: 1px solid #fff;
}
.bg-purple{
    background: #9d5def;
    background: linear-gradient(180deg, #9d5def 0%, #b57efb 100%);
}
.bg-danger{
    background: #d32833;
    background: linear-gradient(180deg, #d32833 0%, #d9444f 100%);
}
.bg-success{
    background: #75953d;
    background: linear-gradient(180deg, #75953d 0%, #82ad3c 100%);
}
.card-text{
    display: flex;
    h4{
        font-size: 18px;
        font-weight: 600;
    }
}
.progress-box{
    padding: 20px 15px;
    min-height: 138px;
    .count{
        font-size: 30px;
        font-weight: 700;
        color: #fff;
        text-align: right;
        margin: 0;
        small{
            font-size: 14px;
        }
    }
    h4{
        margin-left: 10px;
    }
}
.badges{
    ul{
        display: flex;
        li{
            margin: 15px 4px;
            text-align: center;
            h6{
                font-size: 13px;
                color: #7E8CA4;
                margin: 0;
                margin-top: 8px;
            }
        }
    }
}

.chat-student-list{
    ul{
        display: flex;
        li{
            position: relative;
            border-radius: 8px;
            overflow: hidden;
            margin-right: 12px;
            &:last-child{
                margin-right: 0;
            }
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
}
.chat-student-name{
    background: rgba(83,102,133,0.6);
    color: #fff;
    font-size: 14px;
    border-radius: 8px;
    padding: 4px 8px;
    margin: 0;
    width: 160px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
}
.chat-teacher{
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 10px;
    img{
        width: 100%;
    }
    .chat-teacher-name{
        background: rgba(83,102,133,0.6);
        color: #fff;
        font-size: 14px;
        border-radius: 8px;
        padding: 4px 8px;
        margin: 0;
        width: 160px;
        position: absolute;
        top: 0;
        right: 0;
        text-align: center;
    } 
    ul{
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 40px;
        left: 0;
        right: 0;
        margin: auto;
        li{
            margin: 5px 8px;
            img{
                width: 50px;
            }
        }
    }
}
.group-chat{
    ul{
        li{
            width: 100%;
            overflow: hidden;
            margin-bottom: 10px;
        }
    }
}
.to-text{
    display: flex;
    .sender-avatar{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 8px;
        overflow: hidden;
        margin-top: 38px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .avatar-text-bg{
        background: #3247d7;
        background: linear-gradient(180deg, #3247d7 0%, #2876e9 100%);
        padding: 5px 8px;
        -webkit-border-radius: 8px;
        -webkit-border-top-left-radius: 0;
        -moz-border-radius: 8px;
        -moz-border-radius-topleft: 0;
        border-radius: 8px;
        border-top-left-radius: 0;  
        position: relative;
        p{
            color: #fff;
            margin: 0;
            font-size: 14px;
        }
        .time{
            color: #fff;
            font-size: 13px;
            text-align: right;
        }
    }
    .sender-text{
        width: 84%;
        h6{
            font-size: 14px;
            color: #535272;
            margin: 0;
            margin-bottom: 4px;
            margin-top: 15px;
        }
    }
}

.from-text{
    width: 82%;
    float: right;
    h6{
        font-size: 14px;
        color: #535272;
        margin: 0;
        margin-bottom: 4px;
        margin-top: 15px;
    }
    .sender-text{
        background: #F4F4F4;
        padding: 5px 8px;
        -webkit-border-radius: 8px;
        -webkit-border-top-right-radius: 0;
        -moz-border-radius: 8px;
        -moz-border-radius-topright: 0;
        border-radius: 8px;
        border-top-right-radius: 0;
        position: relative;
        p{
            color: #535272;
            margin: 0;
        }
        .time{
            color: #536685;
            font-size: 13px;
            text-align: right;
        }
    }
}
.chat-input{
    position: relative;
    margin-top: 120px;
    .form-control{
        padding-right: 106px;
        box-shadow: 0 0 6px rgba(0,0,0,0.20);
    }
    .chat-attach{
        position: absolute;
        top: 14px;
        right: 52px;
        ul{
            display: flex;
            li{
                margin: 0 6px;
            }
        }
    }
    .chat-send{
        position: absolute;
        top: 6px;
        right: 7px;
        padding: 7px 10px;
        border-radius: 6px;
        img{
            width: 20px;
        }
    }
}
.watch-video-btn{
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #536685;
    background: #fff;
    border-radius: 5px;
    padding: 6px 15px;
    font-size: 12px;
    font-weight: 500;
    &:hover{
        color: #000;
    }
}
.watch-btn-option{
    position: absolute;
    right: 10px;
    top: 10px;
}
.video-box{
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
    img{
        max-width: 100%;
    }
    .dropdown-menu{
        min-width: 7rem;
        padding: 0;
        font-size: 14px;
    }
    .dropdown-divider {
        margin: .2rem 0;
    }
    .video-box-text{
        position: absolute;
        top: 20px;
        left: 14px;
        h4{ 
            font-size: 14px;
            color: #fff;
            margin-bottom: 0;
        }
        p{
            font-size: 12px;
            color: #fff;
            font-weight: 700;
        }
    }
    .watch-btn{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 54px;
        height: 54px;
        img{
            width: 54px;
        }
    }
}
.video-category{
    border-bottom: 1px solid #536685;
    margin-bottom: 15px;
    padding: 0 10px;
    margin-top: 15px;
    ul{
        display: flex;
        justify-content: space-between;
        li{
            a{
                color: #536685;
                font-size: 17px;
                padding: 8px 8px;
                display: inline-block;
                position: relative;
                margin-bottom: -1px;
            }
            &.active{
                a{
                    color: #287AEB;
                    border-bottom: 3px solid #287AEB;
                }
            }
            &:hover{
                a{
                    color: #287AEB;
                    border-bottom: 3px solid #287AEB;
                }
            }
        }
    }
}
.iagree{
    .form-check-input[type=checkbox]{
        font-size:18px;
        margin-top:2px;
    }
    label{
        font-size: 14px;
    }
}

.nav-tabs .nav-link {
    font-size: 18px;
    color: #536685;
    margin-bottom: 0px;
    border-radius: 0;
    padding: 6px 0px;
    margin-right: 30px;
    border-bottom: 3px solid transparent;
    border: none;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #287AEB;
    background-color: transparent;
    border: none;
    border-bottom: 3px solid #287AEB;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border: none;
    border-bottom: 3px solid #287AEB;
    margin-bottom: 0px;
    color: #287AEB;
}
.nav-tabs {
    border: none;
}
.account-setting{
    .form-control{
        background: #fff;
        border: 1px solid #536685;
        color: #536685;
        font-size: 14px;
    }
    .form-select{
        border: 1px solid #536685;
        color: #536685;
        font-size: 14px;
    }
    label{
        font-size: 16px;
        color: #536685;
        margin-left: 5px;
        margin-bottom: 3px;
    }
}
.accessbility{
    padding: 10px;
    label{
        color: #536685;
    }
    .form-check-input[type=checkbox]{
        font-size: 18px;
        margin-top: 2px;
    }
}
.offcanvas-start {
    width: 350px;
}
.mobile-side-menu{
    ul{
        li{
            margin: 8px 0;
            a{
                color: #536685;
                font-size: 18px;
                padding: 12px 20px;
                border-radius: 8px;
                display: block;
                display: flex;
                align-items: center;
                span{
                    background-repeat: no-repeat;
                    width: 30px;
                    height: 30px;
                    overflow: hidden;
                    background-position: 0px 0;
                    display: inline-block;
                    background-size: cover;
                    margin-right: 10px;
                }
                &.active{
                    background: rgb(45,121,241);
                    background: linear-gradient(180deg, rgba(45,121,241,1) 0%, rgba(56,71,221,1) 100%);
                    color: #fff;
                    span{
                        background-position: 0px -30px;
                    }
                }
            }
        }
    }
}
.offcanvas-header {
    padding-bottom: 0;
}
.burger-menu{
    display: none;
}
.user-short-text-mobile{
    margin-bottom: 10px;
}
.applyschedule{
    label{
        color: #536685;
    }
}
.DatePicker_dateDayItem__hSo6n {
    width: 46px !important;
    height: 56px !important;
    border-radius: 20px !important;
    color: #536685;
}

// admin 
.bg-grey{
    background: #F3F5FC !important;
}
.form-check{
    &.text-grey{
        color: #536685;
    }
}
.no-shadow{
    box-shadow: none !important;
}
.upload-profile{
    width: 170px;
    height: 170px;
    margin: 30px auto;
    position: relative;
    .upload-profile-img{
        width: 170px;
        height: 170px;
        border-radius: 50%;
        overflow: hidden;
        background: #d6e3ff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    img{
        width: 100%;
        height: 100%;
       
    border-radius: 50%;
    }
    .upload-icon{
        width: 50px;
        height: 50px;
        background: #f3f5fc;
        border-radius: 50%;
        position: absolute;
        bottom: 10px;
        right: 0;
        input[type="file"]{
            opacity: 0;
            width: 50px;
            height: 50px;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    &.student-setting{
        width: 100px;
        height: 100px;
        margin: 10px 2px;
        .upload-profile-img{
            width: 100px;
            height: 100px;
            background: #e9ecf4;
            padding: 10px;
        }
        .upload-icon{
            width: 30px;
            height: 30px;
        }
    }
}
.faci-profile{
    width: 200px;
    height: 200px;
    margin: 30px auto;
    text-align: center;
    color: #536685;
    h6{
        font-size: 14px;
        margin: 0;
        margin-top: 8px;
    }
    p{
        font-size: 12px;
    }
    img{
        width: 100%;
        height: 100%;
    }
    .faci-profile-img{
        width: 150px;
        height: 150px;
        margin: auto;
        border-radius: 50%;
        background: #E9ECF4;
        overflow: hidden;
    }
} 

.filters{
    margin-top: 5px;
    margin-bottom: 5px;
    ul{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        li{
            margin-left: 15px;
            a{
                color: #536685;
                font-size: 14px;
                font-weight: 400;
                box-shadow: none;
                background: #E9ECF4;
                padding: 8px 25px;
                min-height: 36px;
                img{
                    margin-left: 6px;
                }
                // border: 1px solid #E9ECF4;
            }
            .form-select{
                color: #536685;
                font-size: 14px;
                font-weight: 400;
                box-shadow: none;
                min-height: 20px;
                padding: 5px 9px;
                background: #E9ECF4;
            }
            &:hover{
                a{
                    color: #fff;
                    background: #287AEB;
                    // border: 1px solid #287AEB;
                }
                .form-select{
                    color: #fff;
                    background: #287AEB;
                }
            }
        }
    }
}
.school-box{
    background: rgb(229,230,232);
    background: linear-gradient(180deg, rgba(229,230,232,1) 0%, rgba(255,255,255,1) 100%);
    border-radius: 20px;
    padding: 20px 17px;
    text-align: center;
    margin-top: 15px;
    .school-box-logo{
        flex-flow: row;
        height: 130px;
        margin-bottom: 15px;
        // overflow: hidden;
        img{
            height: 100%;
            width: 100%;
        }
    }
    h4{
        font-size: 18px;
        color: #536685;
        font-weight: 500;
        margin: 0;
        height: 40px;
        overflow: hidden;
    }
}
.btn-primary{
    &.solid{
        background: #287AEB;
        border: 1px solid #287AEB;
    }
}
.school-logo-circle{
    width: 160px;
    height: 160px;
    border-radius: 50%;
    overflow: hidden;
    background: #E9ECF4;
    padding: 10px;
    margin: 5px auto;
    img{
        height: 100%;
        width: 100%;
        border-radius: 50%;
    }
}
.school-logo-small{
    width: 80px;
    height: 80px;
    border-radius: 8px;
    background: #E9ECF4;
    overflow: hidden;
    margin-right: 15px;
    text-align: center;
    img{
        height: 100%;
        width: 100%;
    }
}
.school-name{
    text-align: center;
    color: #536685;
    margin-bottom: 20px;
    h5{
        font-size: 20px;
    }
    h6{
        font-size: 14px;
    }
}
.school-side-details{
    position: relative;
    .form-control{
        min-height: 42px;
        color: #536685;
        font-size: 14px;
    }
    .form-select{
        min-height: 42px;
    }
    .school-more-option{
        position: absolute;
        top: 0;
        right: 0;
        .dropdown{
            .btn{
                background: none;
                border: none;
                padding: 0;
                box-shadow: none;
            }
            .dropdown-item {
                padding: 0 10px;
                color: #536685;
                font-size: 14px;
                background: #fff;
                &:hover{
                    background: #fff; 
                    color: #287aeb;
                }
            }
            .dropdown-item.active, .dropdown-item:active{
                background: #fff;
                color: #287aeb;
            }
        }
    }
    .same-width{
        min-width: 140px;
    }
}

.teacher-contact-detail{
    margin-bottom: 15px;
    ul{
        display: flex;
        justify-content: space-between;
        .phone-li .show-phone{
            visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
        }
        li{
            margin-bottom: 15px;
        }
        .phone-li:hover .show-phone{
            visibility: visible;
        }
    }
}
.about-teacher{
    color: #536685;
    .tutor-subject{
        padding-left: 0;
        width: 100%;
        ul{
            flex-wrap: wrap;
            li{
                padding: 3px 10px;
                border-radius: 4px;
                margin-right: 4px;
                margin-bottom: 4px;
            }
        }
    }
    h4{
        font-size: 16px;
        margin-bottom: 0;
    }
    p{
        font-size: 14px;
    }
}
.table-striped{
    tr{
        th{
            padding: 15px 5px;
            font-size: 16px;
            color: #536685;
            small{
                font-size: 10px;
                display: block;
            }
        }
        td{
            vertical-align: middle;
            color: #536685;
            font-size: 14px;
            select{
                border: none;
                background: transparent;
                font-size: 13px;
                color: #536685;
                padding: 13px 0;
            }
        }
        &:hover{
            td{
                background: #287AEB !important;
                color: #fff !important;
                select{
                    color: #dbdbdb;
                }
            }
            
        }
    }
}
.table-striped>tbody>tr:nth-of-type(odd)>* {
    color: #536685;
    background-color: #F7F9FF;
}
.table>:not(caption)>*>* {
    background-color: #fff;
    border: none;
    box-shadow: none;
}
.table>:not(:first-child) {
    border: none;
}

.statistic-box{
    background: #E9ECF4;
    color: #536685;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 5px #d6def1;
    margin-top: 15px;
    h4{
        font-size: 13px;
        min-height: 34px;
    }
    h3{
        font-size: 34px;
        font-weight: 600;
        margin: 0;
    }
    &:hover{
        background: rgb(45,121,241);
        background: linear-gradient(180deg, rgba(45,121,241,1) 0%, rgba(56,71,221,1) 100%);
        color: #fff;
    }
}
.no-space{
    justify-content: inherit !important;
}

.student-rating-box{
    border-radius: 15px;
    background: #fff;
    box-shadow: 0 0 6px rgba(0,0,0,0.20);
    position: relative;
    margin-top: 52px;
    .rating-avatar{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        background: #E9ECF4;
        padding: 10px;
        top: -52px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: -52px;
        img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }
    .rating-description{
        text-align: center;
        color: #536685;
        padding: 0 15px;
        padding-bottom: 15px;
        padding-top: 44px;
        h4{
            font-size: 18px;
            margin-top: 15px;
            margin-bottom: 5px;
        }
        h5{
            font-size: 14px;
        }
        p{
            margin: 0;
        }
    }
    .star-count{
        margin-bottom: 15px;
    }
}

.attach-file-res{
    width: 152px;
    position: relative;
    margin: 0 auto;
    text-align: center;
    input[type="file"]{
        position: absolute;
        top: 0;
        left: 0;
        width: 134px;
        opacity: 0;
    }
}
.upload-resource{
    background: #F3F5FC;
    border-radius: 10px;
    padding: 40px 30px;
    text-align: center;
    position: relative;
    input[type="file"]{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
}

.dash-box-tutor{
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
    img{
        max-width: 100%;
    }
    .remaining-hrs{
        position: absolute;
        left: 15px;
        top: 20px;
        span{
            font-size: 16px;
            color: #0d6efd;
            background: #fff;
            border-radius: 5px;
            padding: 4px 10px;
            font-weight: 500;
        }
    }
    .tutor-user{
        position: absolute;
        right: 15px;
        top: 12px;
        color: #fff;
        text-align: center;
        h4{
            font-size: 12px;
            margin: 0;
            margin-top: 4px;
        }
        p{
            font-size: 10px;
            margin: 0;
            line-height: 14px;
        }
        .tutor-user-img{
            background: #fff;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;
            margin: auto;
            img{
                max-width: 100%;
            }
        }
    }
    .dash-box-text{
        position: absolute;
        bottom: 20px;
        left: 20px;
        h4{ 
            font-size: 16px;
            color: #fff;
            margin-bottom: 0;
        }
        p{
            font-size: 12px;
            color: #fff;
            font-weight: 700;
            margin-bottom: 0;
        }
    }
}

.student-list{
    margin: 20px 0;
    ul{
        display: flex;
        li{
            width: 14%;
            position: relative;
            .online-status{
                width: 16px;
                height: 16px;
                border-radius: 50%;
                position: absolute;
                bottom: 42px;
                right: 30px;
                &.active{
                    background: #1BC02E;
                    border: 1px solid #fff;
                }
            }
            .tutor-user{
                color: #536685;
                text-align: center;
                h4{
                    font-size: 14px;
                    margin: 0;
                    margin-top: 8px;
                }
                p{
                    font-size: 12px;
                    margin: 0;
                    line-height: 14px;
                }
                .tutor-user-img{
                    background: #fff;
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin: 5px auto;
                    box-shadow: 4px 4px 5px rgba(0,0,0,0.25);
                    img{
                        max-width: 100%;
                    }
                }
            }
        }
    }
}
.attach-assignment{
    position: relative;
    input[type="file"]{
        position: absolute;
        top: 0;
        left: 0;
        height: 50px;
        opacity: 0;
        width: 220px;
    }
}
.alert{
    font-size: 16px;
    border-radius: 10px;
    padding: 12px 15px;
    .fa{
        font-size: 18px;
    }
}
.alert-danger {
    color: #F53C3C;
    background-color: #FDF0EF;
    border-color: #FDF0EF;
}
.watch-btn-option.dropdown{
    .btn{
        background: none;
        border: none;
        padding: 0;
        box-shadow: none;
        &:focus{
            box-shadow: none;
        }
    }
    .dropdown-toggle::after{
        display: none;
    }
}
.btn-close {
    background: transparent url("../public/images/close.png") center/1em auto no-repeat;
    opacity: 1;
}
.slctGrade li{
    position: relative;
}
.slctGrade input {
    position: absolute;
    top:0;
    left:0;
    z-index: 99;
    width: 100%;
    height: 100%;
    visibility: hidden;
}
.react-time-picker {
    width: 100%;
    border: 1px solid #E9ECF4 !important;
    min-height: 52px;
    border-radius: 9px;
}
.react-time-picker__wrapper {
    border: none;
    padding-left: 15px;
    padding-right: 15px;
}
.react-time-picker__inputGroup__input {
    color: #536685;
}
span.err{
    color: #f00;
}
.signup-box .signup-right {
    max-height: 660px;
    overflow-y: scroll;
}
.DatePicker_dayLabel__efdEo {
    margin: 0;
}
.DatePicker_dateDayItem__486u2 {
    width: 50px !important;
    height: 50px !important;
}
.table-striped tr:hover td .declineSlct {
    color: #000;
    background-color: #fff !important;

}
.table-striped tr td .declineSlct {
    padding-left: 10px;
    padding-right: 10px;
    background-color: #E9ECF4 !important;
    color:#536685;
    background: url(../public/images/drop-icon.png);
    background-repeat: no-repeat;
    background-position:95% center;
    background-size: 16px;
    position: relative;
}
.declineSlct option:nth-child(1){
    position: relative;
   background-color: #1BC02E;
}
.userProfile {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    margin-right: 10px;
    background: #E9ECF4;
    padding: 4px;
    padding-bottom: 0;
    img{
        max-width: 100%;
    }
}
.profilStingSec .table-striped tr:hover td a img {
    filter: brightness(0) invert(1);
}
.popcamraIcn{
    display: flex;
    justify-content: end;
}
.avtarProfile {
    background-color: #E3E7F0;
    position: relative;
    display: flex;
    border-radius: 15px;
    padding: 15px 15px 0 15px;
    min-height: 136px;
    align-items: end;
    img{
        max-width: 100%;
    }
    .dlt{
        position: absolute;
        top: 6px;
        right: 5px;
        max-width: 26px;
    }
}
.uploadTxt{
    color: #2E74EF;
    font-size: 20px;
    margin-top: 20px;
}
.react-calendar {
    width: inherit !important;
    border: none !important;
    font-size: 20px;
    .react-calendar__navigation{
        min-width: 66px;
    }
    .react-calendar__navigation__arrow{
        font-size: 36px;
    }
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
//   border-radius: inherit;
}
.react-calendar__tile--now {
    background: rgb(191, 202, 223);
    // background: #35a7c4;
  }
  
  .react-calendar__tile--now:enabled:hover{
    background: linear-gradient(180deg, rgba(45,121,241,1) 0%, rgba(56,71,221,1) 100%);
    // background: #4db8e2;
  }
  .react-calendar__tile--now:enabled:focus {
    background: rgb(45,121,241);
  }



  .dashboard-wrap  {
    min-height: 100vh;
  }