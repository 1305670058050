@media only screen and (min-width: 1300px) and (max-width: 1400px) {
    .grade-batch ul li:hover img {
        top: -96px;
    }
    .grade-batch ul li.selected img {
        top: -96px;
    }
    .subject-name ul li:hover .sub-img img {
        top: -84px;
    }
    .subject-name ul li.selected .sub-img img {
        top: -84px;
    }
    .subject-name ul li h6 {
        font-size: 12px;
    }
    .subject-name ul li {
        padding: 12px 7px;
    }
    .student-list ul li .online-status {
        bottom: 34px;
        right: 18px;
    }
    .student-list ul li .tutor-user .tutor-user-img {
        width: 60px;
        height: 60px;
    }
    .filters ul li {
        margin-left: 2px;
    }
    .filters .form-select {
        font-size: 13px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .dash-box-tutor .dash-box-text h4 {
        font-size: 13px;
    }
    .dash-box-tutor .dash-box-text p {
        font-size: 11px;
    }
    .dash-box-tutor .tutor-user .tutor-user-img {
        width: 52px;
        height: 52px;
        margin: auto;
    }
    .dash-box-tutor .tutor-user h4 {
        font-size: 11px;
    }
    .video-box .video-box-text {
        top: 34px;
    }
    .dash-box .dash-box-text {
        top: 28px;
        left: 13px;
    }

}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    

}
@media only screen and (max-width: 767px) {
    .navbar-brand img{
        width: 160px;
    }
    .navbar-light .navbar-toggler {
        background: #fff;
    }
    .navbar {
        padding-bottom: 15px;
    }
    .login-box {
        top: 0;
        margin-top: 15px;
    }
    .signup-box {
        flex-direction: column;
        -webkit-border-top-left-radius: 8px;
        -webkit-border-top-right-radius: 8px;
        -moz-border-radius-topleft: 8px;
        -moz-border-radius-topright: 8px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    .signup-box .signup-left {
        width: 100%;
        padding: 15px 15px;
    }
    .signup-box .signup-right {
        width: 100%;
        padding: 15px;
        -webkit-border-bottom-right-radius: 8px;
        -webkit-border-bottom-left-radius: 8px;
        -moz-border-radius-bottomright: 8px;
        -moz-border-radius-bottomleft: 8px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    .signup-step {
        margin-top: 0 !important;
    }
    .grade-batch {
        height: auto;
        overflow-y: initial;
    }
    .grade-batch ul li {
        width: 47%;
        height: 150px;
    }
    .grade-batch ul li.selected img {
        top: -164px;
    }
    .grade-batch ul li:hover img {
        top: -164px;
    }
    .choose-avatar ul li {
        width: 47%;
    }
    .add-details {
        height: inherit;
        padding: 0 5px;
        overflow-y: inherit;
    }
    .subject-name ul li {
        width: 47%;
    }
    .subject-name ul li .sub-img {
        height: 130px;
    }
    .subject-name ul li.selected .sub-img img {
        top: -142px;
    }
    .subject-name ul li:hover .sub-img img {
        top: -142px;
    }
    .subject-name {
        height: inherit;
        overflow-y: inherit;
    }
    .dashboard-wrap {
        flex-direction: column;
    }
    .dashboard-wrap > .left {
        width: 100%;
    }
    .dashboard-wrap > .middle {
        width: 100%;
    }
    .dashboard-wrap > .right {
        width: 100%;
    }
    .dashboard-wrap > .left .dash-logo {
        margin-bottom: 10px;
        padding-left: 0px;
    }
    .tutor-wrap {
        flex-direction: column;
        width: 100%;
    }
    .tutor-action{
        width: 100%;
    }
    .tutor-icon{
        display: none;
    }
    .assignment-box {
        text-align: center;
        min-height: inherit;
    }
    .folder-img {
        min-height: inherit;
    }
    .tutor-item {
        flex-direction: column;
        text-align: center;
        padding: 15px 26px;
    }
    .tutor-avatar {
        margin: auto;
    }
    .tutor-subject ul {
        flex-direction: column;
    }
    .tutor-subject {
        padding-left: 0;
        width: 100%;
        padding-top: 10px;
    }
    .tutor-action ul {
        justify-content: center;
    }
    .tutor-subject ul li {
        margin-right: 0;
        margin-top: 4px;
    }
    .dash-logo{
        display: flex;
        justify-content: space-around;
    }
    .dash-menu{
        display: none;
    }
    .dash-menu.mobile-side-menu{
        display: block;
    }
    .burger-menu{
        display: block;
    }
    .user-short-text{
        display: none;
    }
    .upcom-class {
        margin-top: 0;
    }
    .badges ul {
        flex-direction: column;
    }
    .video-category ul {
        justify-content: center;
        flex-direction: column;
    }
    .video-category ul li{
        text-align: center;
    }
    .video-category {
        padding: 0 0;
    }
    .nav-tabs .nav-link {
        margin-right: 22px;
    }
    .chat-student-name {
        font-size: 10px;
        border-radius: 5px;
        padding: 4px 4px;
        width: 66px;
        bottom: 4px;
        left: 6px;
        line-height: 12px;
    }
    .chat-teacher ul li {
        margin: 5px 1px;
    }

    .student-list ul {
        flex-wrap: wrap;
        flex-direction: row;
    }
    .student-list ul li{
        width: 42%;
    }

    .filters ul {
        justify-content: center;
        flex-direction: column;
    }
    .filters ul li {
        margin-left: 0px;
        margin-bottom: 10px;
    }



}
@media only screen and (max-width: 479px) {
    .navbar-brand img{
        width: 160px;
    }
    .navbar-light .navbar-toggler {
        background: #fff;
    }
    .navbar {
        padding-bottom: 15px;
    }
    .login-box {
        top: 0;
        margin-top: 15px;
    }
    .signup-box {
        flex-direction: column;
        -webkit-border-top-left-radius: 8px;
        -webkit-border-top-right-radius: 8px;
        -moz-border-radius-topleft: 8px;
        -moz-border-radius-topright: 8px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    .signup-box .signup-left {
        width: 100%;
        padding: 15px 15px;
    }
    .signup-box .signup-right {
        width: 100%;
        padding: 15px;
        -webkit-border-bottom-right-radius: 8px;
        -webkit-border-bottom-left-radius: 8px;
        -moz-border-radius-bottomright: 8px;
        -moz-border-radius-bottomleft: 8px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    .signup-step {
        margin-top: 0 !important;
    }
    .grade-batch {
        height: auto;
        overflow-y: initial;
    }
    .grade-batch ul li {
        width: 47%;
        height: 150px;
    }
    .grade-batch ul li.selected img {
        top: -164px;
    }
    .grade-batch ul li:hover img {
        top: -164px;
    }
    .choose-avatar ul li {
        width: 47%;
    }
    .add-details {
        height: inherit;
        padding: 0 5px;
        overflow-y: inherit;
    }
    .subject-name ul li {
        width: 47%;
    }
    .subject-name ul li .sub-img {
        height: 130px;
    }
    .subject-name ul li.selected .sub-img img {
        top: -142px;
    }
    .subject-name ul li:hover .sub-img img {
        top: -142px;
    }
    .subject-name {
        height: inherit;
        overflow-y: inherit;
    }
    .dashboard-wrap {
        flex-direction: column;
    }
    .dashboard-wrap > .left {
        width: 100%;
    }
    .dashboard-wrap > .middle {
        width: 100%;
    }
    .dashboard-wrap > .right {
        width: 100%;
    }
    .dashboard-wrap > .left .dash-logo {
        margin-bottom: 10px;
        padding-left: 0px;
    }
    .tutor-wrap {
        flex-direction: column;
        width: 100%;
    }
    .tutor-action{
        width: 100%;
    }
    .tutor-icon{
        display: none;
    }
    .assignment-box {
        text-align: center;
        min-height: inherit;
    }
    .folder-img {
        min-height: inherit;
    }
    .tutor-item {
        flex-direction: column;
        text-align: center;
        padding: 15px 26px;
    }
    .tutor-avatar {
        margin: auto;
    }
    .tutor-subject ul {
        flex-direction: column;
    }
    .tutor-subject {
        padding-left: 0;
        width: 100%;
        padding-top: 10px;
    }
    .tutor-action ul {
        justify-content: center;
    }
    .tutor-subject ul li {
        margin-right: 0;
        margin-top: 4px;
    }
    .dash-logo{
        display: flex;
        justify-content: space-around;
    }
    .dash-menu{
        display: none;
    }
    .dash-menu.mobile-side-menu{
        display: block;
    }
    .burger-menu{
        display: block;
    }
    .user-short-text{
        display: none;
    }
    .upcom-class {
        margin-top: 0;
    }
    .badges ul {
        flex-direction: column;
    }
    .video-category ul {
        justify-content: center;
        flex-direction: column;
    }
    .video-category ul li{
        text-align: center;
    }
    .video-category {
        padding: 0 0;
    }
    .nav-tabs .nav-link {
        margin-right: 22px;
    }
    .chat-student-name {
        font-size: 10px;
        border-radius: 5px;
        padding: 4px 4px;
        width: 66px;
        bottom: 4px;
        left: 6px;
        line-height: 12px;
    }
    .chat-teacher ul li {
        margin: 5px 1px;
    }

    .filters ul {
        justify-content: center;
        flex-direction: column;
    }
    .filters ul li {
        margin-left: 0px;
        margin-bottom: 10px;
    }


}
